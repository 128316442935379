import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, InputAdornment, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid2";
import {
  IFinantialInformation_selects,
  occupation_classification,
} from "../../../constants/types.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import { yupFinancialValidations } from "../../../common/validations/yupFinancialValidations.ts";
import {
  getValueStorage,
  saveValueStorage,
  wipeValueStorage,
} from "../../../common/storage.ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IRegisterStepState } from "../RegisterComponent.tsx";

export default function FinancialInformation({
  setRegisterStep,
}: IRegisterStepState) {
  const { t } = useTranslation();
  const { getUser, setIsLoading } = useContext(AuthContext);
  const [laboralStatus, setLaboralStatus] = useState<any[]>([]);
  const [periodTypes, setPeriodTypes] = useState<any[]>([]);
  const [occupationClassification, setOccupationClassification] = useState<
    occupation_classification[]
  >([]);
  const [selectedOccupation, setSelectedOccupation] =
    useState<occupation_classification | null>(null);

  useEffect(() => {
    (async () => {
      wipeValueStorage("registerProcessData");
      setIsLoading(true);

      const res: IFinantialInformation_selects = await requestSquematic(
        "GET",
        "/api/app/financial_data/get_selects",
        {},
        getUser.token
      );

      if (res) {
        setLaboralStatus([
          {
            id: "",
            value: "Seleccione",
          },
          ...res.results!.laboral_status.map((item: any) => ({
            id: item.c019_rowid + "",
            value: item.c019_descripcion,
          })),
        ]);

        setPeriodTypes([
          {
            id: "",
            value: "Seleccione",
          },
          ...res
            .results!.period_types.filter(
              (item) => item.c054_estado === "1" && item.c054_rowid !== 10
            )
            .map((item: any) => ({
              id: item.c054_rowid + "",
              value: item.c054_descripcion,
            })),
        ]);
        setOccupationClassification(res.results!.occupation_classification);
      }
      setIsLoading(false);
    })();
  }, []);

  // Mapea las opciones
  const laboralStatusOptions = laboralStatus.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  const periodTypesOptions = periodTypes.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  // Inicializar variables
  const formik = useFormik({
    initialValues: {
      salary: "",
      laboral_status: "",
      occupation: "",
      company: "",
      company_address: "",
      company_phone: "",
      charge: "",
      period_pay: "",
    },
    validationSchema: yupFinancialValidations,
    onSubmit: (values) => {
      let authSesion = getValueStorage("authSession");
      let valuesFormated = {
        salary: values.salary,
        laboral_status: values.laboral_status,
        occupation: "-",
        company: "-",
        company_address: "-",
        company_phone: "-",
        charge: "-",
        period_pay: "-",
      };

      //pensaionado
      if (values.laboral_status === "9") {
        valuesFormated.period_pay = values.period_pay;
      }

      //Independiente sin negocio
      if (values.laboral_status === "4") {
        valuesFormated.occupation = values.occupation;
      }

      //Si es empleado o independiente con negocio
      if (["3", "5", "6"].includes(values.laboral_status)) {
        valuesFormated = {
          ...valuesFormated,
          occupation: values.occupation,
          company: values.company,
          company_address: values.company_address,
          company_phone: values.company_phone,
          charge: values.charge,
          period_pay: values.period_pay,
        };
      }

      saveValueStorage("registerProcessData", {
        ...valuesFormated,
        houseTenure: authSesion ? authSesion.houseTenure : "1",
      });
      let obj = {
        prevStep: 6,
        nextStep: 7,
        desc: "el usuario lleno la informacion financiera",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    },
  });

  // Limpiar los campos al cambiar el estado de laboral
  const cleanInputs = (values: any) => {
    // Limpiar los errors
    formik.setTouched({}, false);
    //pensaionado
    if (values === "9") {
      formik.setFieldValue("occupation", "");
      formik.setFieldValue("company", "");
      formik.setFieldValue("company_address", "");
      formik.setFieldValue("company_phone", "");
      formik.setFieldValue("charge", "");
      setSelectedOccupation(null);
    }

    //Independiente sin negocio
    if (values === "4") {
      formik.setFieldValue("company", "");
      formik.setFieldValue("company_address", "");
      formik.setFieldValue("company_phone", "");
      formik.setFieldValue("charge", "");
      formik.setFieldValue("period_pay", "");
    }

    //Si es empleado o independiente con negocio
    if (["7", "8", "11"].includes(values)) {
      formik.setFieldValue("occupation", "");
      formik.setFieldValue("company", "");
      formik.setFieldValue("company_address", "");
      formik.setFieldValue("company_phone", "");
      formik.setFieldValue("charge", "");
      formik.setFieldValue("period_pay", "");
      setSelectedOccupation(null);
    }
  };

  // No utilizar en onchange, porque genera ciclos infinitos
  useEffect(() => {
    cleanInputs(formik.values.laboral_status);
  }, [formik.values.laboral_status]);

  // Input para convertir a miles
  const handleChange = (event) => {
    // Remover cualquier caracter que no sea un dígito
    const rawValue = event.target.value.replace(/\D/g, "");

    // Formatear a pesos de miles
    const formattedValue = new Intl.NumberFormat("es-CO").format(rawValue);

    formik.setFieldValue("salary", formattedValue);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.form")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.forRequestCredit")}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"left"} mt={5}>
        <Box>
          <Box style={{ color: colors.darkGray, fontSize: 13 }}>
            {t("common.completeAllFields")}
          </Box>
          <Box
            className="text-primary"
            style={{
              fontSize: 15,
              fontWeight: 700,
              marginTop: 0,
              color: colors.primary,
              textTransform: "uppercase",
              lineHeight: 1,
            }}
          >
            {t("financialInformation.title")}
          </Box>
        </Box>
        <Grid container spacing={2} columns={12} mt={2.5}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputField
              name="salary"
              label={t("financialInformation.salary")}
              formik={formik}
              onChange={handleChange}
              disabled={getUser.isLoading}
              maxLength={8}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputField
              name="laboral_status"
              formik={formik}
              label={t("financialInformation.laboralStatus")}
              type="select"
              options={laboralStatusOptions}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid
            size={{ xs: 12 }}
            sx={{
              display: !["3", "4", "5", "6"].includes(
                formik.values.laboral_status
              )
                ? "none"
                : "block",
            }}
          >
            {/* OCUPACION */}
            <Autocomplete
              options={occupationClassification}
              getOptionLabel={(option) => option.c016_descripcion}
              value={selectedOccupation}
              onChange={(event, newValue) => {
                setSelectedOccupation(newValue);
                // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                formik.setFieldValue(
                  "occupation",
                  newValue ? newValue.c016_rowid + "" : ""
                );
              }}
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = occupationClassification.find(
                  (option) => option.c016_descripcion === inputValue
                );
                if (!matchedOption) {
                  formik.setFieldValue("occupation", "");
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <InputField
                  {...params}
                  name="occupation"
                  label={t("financialInformation.ocupation")}
                  formik={formik}
                  placeholder={
                    occupationClassification.length === 0 ? "" : "Seleccione"
                  }
                />
              )}
            />
          </Grid>
          {["3", "5", "6"].includes(formik.values.laboral_status) && (
            <>
              <Grid size={{ xs: 12, sm: 6 }}>
                {/* EMPRESA */}
                <InputField
                  name="company"
                  formik={formik}
                  label={t("financialInformation.company")}
                  disabled={getUser.isLoading}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <InputField
                  name="company_address"
                  formik={formik}
                  label={t("financialInformation.companyDir")}
                  disabled={getUser.isLoading}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, xl: 6 }}>
                <InputField
                  name="company_phone"
                  formik={formik}
                  label={t("financialInformation.companyTel")}
                  disabled={getUser.isLoading}
                  maxLength={12}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault(); // Prevenir la entrada si no es un número
                    }
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, xl: 6 }}>
                <InputField
                  name="charge"
                  formik={formik}
                  label={t("financialInformation.charge")}
                  disabled={getUser.isLoading}
                />
              </Grid>
            </>
          )}
          {["3", "5", "6", "9"].includes(formik.values.laboral_status) && (
            <Grid
              size={12}
            >
              <InputField
                name="period_pay"
                formik={formik}
                label={t("financialInformation.periodPay")}
                type="select"
                options={periodTypesOptions}
                disabled={getUser.isLoading}
              />
            </Grid>
          )}
        </Grid>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginTop={8}
        >
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
            }}
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={getUser.isLoading}
          >
            {t("common.continue")}
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}
