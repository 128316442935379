import React from "react";
import { requestSquematic } from "../../../../common/CommonFunctions.tsx";
import {
  Autocomplete,
  Box,
  FormHelperText,
} from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { CssTextField } from "../../../../components/InputField.tsx";
import { AuthContext } from "../../../../hooks/useAuth.tsx";

export default function FormsInputLocation({
  names,
  setChanges,
  id,
  isLoading,
  setValids,
  setIsLoading,
  token,
}) {
  const { t } = useTranslation();
  const { getUser } = React.useContext(AuthContext);

  const [location, setLocation] = React.useState({
    city: "",
    department: "",
    country: "169",
    locality: "",
    neighborhood: "",
    name: "",
  });
  const [departmentList, setDepartmentList] = React.useState<any[]>([]);
  const [departmentSelected, setDepartmentSelected] = React.useState<{
    id: number;
    value: string;
  } | null>(null);
  const [cityList, setCityList] = React.useState<any[]>([]);
  const [citySelected, setCitySelected] = React.useState<{
    id: number;
    value: string;
  } | null>(null);
  const [localityList, setLocalityList] = React.useState<any[]>([]);
  const [localitySelected, setLocalitySelected] = React.useState<{
    id: number;
    value: string;
  } | null>(null);
  const [neighList, setNeighList] = React.useState<any[]>([]);
  const [neighSelected, setNeighSelected] = React.useState<{
    id: number;
    value: string;
  } | null>(null);
  const [nothing, setNothing] = React.useState(false);
  const errorRefs = React.useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [isError, setIsError] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    const observers: MutationObserver[] = [];

    Object.keys(errorRefs.current).forEach((key) => {
      const errorElement = errorRefs.current[key];
      if (!errorElement) return;

      const observer = new MutationObserver(() => {
        if (errorElement) {
          setIsError((prevState) => ({
            ...prevState,
            [key]: window.getComputedStyle(errorElement).display === "block",
          }));
        }
      });

      observer.observe(errorElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      observers.push(observer);
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [getUser.isLoading]);

  const fetchDepartments = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_departments",
      {
        c102_id_pais: "169",
      },
      token
    );

    if (data) {
      setDepartmentList(
        data
          .results!.map((deparments: any) => ({
            id: deparments.c102_id,
            value: deparments.c102_descripcion,
          }))
          .sort((a: any, b: any) => a.value.localeCompare(b.value))
      );
    }
    setIsLoading(false);
  };

  const fetchCities = async (department: string) => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_cities",
      {
        c103_id_pais: "169",
        c103_id_depto: department,
      },
      token
    );

    if (data) {
      setCityList(
        data.results!.map((deparments: any) => ({
          id: deparments.c103_id,
          value: deparments.c103_descripcion,
        }))
      );
    }

    setIsLoading(false);
  };

  const fetchBarrios = async (department: string, city: string) => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/neighborhood/get_by_location",
      {
        c104_id_pais: "169",
        c104_id_depto: department,
        c104_id_ciudad: city,
      },
      token
    );

    if (data) {
      const filteredResults = data!.results.filter(
        ({ c104_ind_estado }: any) => c104_ind_estado?.trim() === "1"
      );
      // Si no hay barrios, se muestra un mensaje de alerta
      if (filteredResults.length === 0) {
        setNeighList([]);
        setNothing(true);
      } else {
        setNeighList(
          filteredResults
            .map(({ c104_id, c104_descripcion }: any) => ({
              id: c104_id.toString().trim(),
              value: c104_descripcion,
            }))
            .sort((a: any, b: any) =>
              a.value < b.value ? -1 : a.value > b.value ? 1 : 0
            )
        );
      }
    }

    setIsLoading(false);
  };

  const fetchLocalitiesBogota = async (department: string, city: string) => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/locality/get_by_location",
      {
        c108_id_pais: "169",
        c108_id_depto: department,
        c108_id_ciudad: city,
      },
      token
    );

    if (data) {
      setLocalityList(
        data.results!.map((locality: any) => ({
          id: locality.c106_rowid,
          value: locality.c106_descripcion,
          neighs: locality.communes_by_locality.map(({ commune }: any) =>
            commune.neighborhoods_by_commune.map(
              ({ neighborhood }: any) => neighborhood
            )
          ),
        }))
      );
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    setValids((state: any) => ({
      ...state,
      [id]:
        location.city !== "" &&
        location.department !== "" &&
        // location.neighborhood !== "",
        (names[3] ? location.neighborhood !== "" : true),
    }));

    // Verificar y actualizar el estado de isError
    Object.keys(location).forEach((key) => {
      if (location[key] !== "") {
        setIsError((prevState) => ({
          ...prevState,
          [`errorM_${id}_${key}`]: false,
        }));
      }
    });
  }, [location, setLocation]);

  const handleChange = React.useCallback(
    (value: any) => {
      setLocation((state) => ({ ...state, ...value }));
      setChanges((state: any) => ({
        ...state,
        [id]: { ...state[id], ...value },
      }));
    },
    [setLocation]
  );

  React.useEffect(() => {
    setValids((state: any) => ({
      ...state,
      [id]: false,
    }));

    fetchDepartments();
    handleChange({ country: "169", department: "", city: "" });
    setDepartmentSelected(null);
    setCitySelected(null);
    setLocalitySelected(null);
    setNeighSelected(null);
  }, []);

  // Solo aplica para borrar el error de barrio visualmente
  const errorHiddenNeighborhood = () => {
    setNeighSelected(null);
    setNothing(false);
    setNeighList([]);
    setIsError((prevState) => ({
      ...prevState,
      [`errorM_${id}_neighborhood`]: false,
    }));
    const errorElement = errorRefs.current[`errorM_${id}_neighborhood`];
    if (errorElement) {
      // oculta el mensaje de error
      errorElement.style.display = "none";
    }
  };

  return (
    <Box style={{ marginTop: 1, zIndex: 20 }}>
      {/* DEPARTAMENTO */}
      <Box mt={1}>
        <label
          style={{
            fontSize: 13,
            fontWeight: 600,
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            color: isError[`errorM_${id}_department`] ? colors.red : "",
          }}
          htmlFor={id}
        >
          {t(names[1])}
        </label>
      </Box>
      <Autocomplete
        options={departmentList}
        getOptionLabel={(option) => option?.value}
        value={departmentSelected}
        onChange={(event, newValue) => {
          setLocation({
            ...location,
            department: "",
            city: "",
            locality: "",
            neighborhood: "",
          });
          setIsError((prevState) => ({
            ...prevState,
            [`errorM_${id}_city`]: false,
          }));
          const errorElement = errorRefs.current[`errorM_${id}_city`];
          if (errorElement) {
            // oculta el mensaje de error
            errorElement.style.display = "none";
          }
          setCitySelected(null);
          setLocalitySelected(null);
          setLocalityList([]);
          setCityList([]);
          errorHiddenNeighborhood();
          setChanges((state: any) => ({
            ...state,
            [id]: {
              ...state[id],
              department: "",
              city: "",
              locality: "",
              neighborhood: "",
            },
          }));

          if (!newValue) return;

          fetchCities(newValue.id);

          setLocation({ ...location, department: newValue.id });

          setDepartmentSelected(newValue);

          handleChange({
            department: newValue.id,
            city: "",
            neighborhood: "",
            locality: "",
          });

          if (newValue.id !== "") {
            let element = document.getElementById(`errorM_${id}_department`);
            if (element) {
              element.style.display = "none";
            }
          }
        }}
        disabled={departmentList.length === 0 || getUser.isLoading}
        onInputChange={(event, inputValue) => {
          // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
          const matchedOption = departmentList.find(
            (option) => option?.value === inputValue
          );
          if (!matchedOption) {
            setLocation({ ...location, department: "" });
            setDepartmentSelected(null);
          }
        }}
        noOptionsText={t("common.empty")}
        renderInput={(params) => (
          <CssTextField
            {...params}
            isError={isError[`errorM_${id}_department`]}
            isBlackTheme={false}
            name={id}
            disabled={isLoading}
            placeholder={departmentList.length === 0 ? "" : "Seleccione"}
            inputProps={{
              ...params.inputProps,
              readOnly: !!location.department, // Si hay una selección, el campo será de solo lectura
            }}
          />
        )}
      />
      <FormHelperText
        id={`errorM_${id}_department`}
        ref={(el) => (errorRefs.current[`errorM_${id}_department`] = el)}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
      {/* CIUDAD */}
      <Box mt={1}>
        <label
          style={{
            fontSize: 13,
            fontWeight: 600,
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            color: isError[`errorM_${id}_city`]
              ? colors.red
              : cityList.length === 0
              ? colors.disabled
              : "black",
          }}
          htmlFor={id}
        >
          {t(names[2])}
        </label>
      </Box>

      <Autocomplete
        options={cityList}
        getOptionLabel={(option) => option?.value}
        value={citySelected}
        onChange={(event, newValue) => {
          setLocation({
            ...location,
            city: "",
            locality: "",
            neighborhood: "",
          });
          // Solo aplica para borrar el error de localidad visualmente
          setIsError((prevState) => ({
            ...prevState,
            [`errorM_${id}_locality`]: false,
          }));
          errorHiddenNeighborhood();
          setLocalitySelected(null);
          setLocalityList([]);

          setChanges((state: any) => ({
            ...state,
            [id]: {
              ...state[id],
              city: "",
              locality: "",
              neighborhood: "",
            },
          }));

          if (!newValue) return;

          handleChange({ city: newValue.id, neighborhood: "", locality: "" });

          if (
            location.department.toString() === "11" &&
            newValue.id.toString() === "1"
          ) {
            fetchLocalitiesBogota(location.department, newValue.id);
          } else {
            fetchBarrios(location.department, newValue.id);
          }
          setLocation({ ...location, city: newValue.id });

          setCitySelected(newValue);

          if (newValue.id !== "") {
            let element = document.getElementById(`errorM_${id}_city`);
            if (element) {
              element.style.display = "none";
            }
          }
        }}
        disabled={
          isLoading || location.department === "" || cityList.length === 0
        }
        onInputChange={(event, inputValue) => {
          // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
          const matchedOption = cityList.find(
            (option) => option?.value === inputValue
          );
          if (!matchedOption) {
            setLocation({ ...location, city: "" });
            setCitySelected(null);
          }
        }}
        noOptionsText={t("common.empty")}
        renderInput={(params) => (
          <CssTextField
            {...params}
            isError={isError[`errorM_${id}_city`]}
            isBlackTheme={false}
            name={id}
            disabled={isLoading}
            placeholder={cityList.length === 0 ? "" : "Seleccione"}
            inputProps={{
              ...params.inputProps,
              readOnly: !!location.city, // Si hay una selección, el campo será de solo lectura
            }}
          />
        )}
      />
      <FormHelperText
        id={`errorM_${id}_city`}
        ref={(el) => (errorRefs.current[`errorM_${id}_city`] = el)}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
      {names.length > 3 ? (
        location.department.toString() === "11" &&
        location.city.toString() === "1" ? (
          // Localidad
          <>
            <Box mt={1}>
              <label
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  textWrap: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: isError[`errorM_${id}_locality`]
                    ? colors.red
                    : localityList.length === 0
                    ? colors.disabled
                    : "black",
                }}
                htmlFor={id}
              >
                {t(names[3])}
              </label>
            </Box>

            <Autocomplete
              options={localityList}
              getOptionLabel={(option) => option?.value}
              value={localitySelected}
              onChange={(event, newValue) => {
                setLocation({
                  ...location,
                  locality: "",
                  neighborhood: "",
                });

                errorHiddenNeighborhood();
                setLocalitySelected(null);
                setChanges((state: any) => ({
                  ...state,
                  [id]: {
                    ...state[id],
                    locality: "",
                    neighborhood: "",
                  },
                }));
                if (!newValue) return;

                handleChange({ locality: newValue.id });

                setLocalitySelected(newValue);

                const neighs = localityList.find(
                  ({ id }) => id.toString() === newValue.id.toString()
                )!.neighs;

                setNeighList(
                  neighs
                    .flat(Infinity)
                    .filter(
                      ({ c104_ind_estado }: any) =>
                        c104_ind_estado?.trim() === "1"
                    )
                    .map(({ c104_id, c104_descripcion }: any) => ({
                      id: c104_id.toString().trim(),
                      value: c104_descripcion,
                    }))
                    .sort((a: any, b: any) =>
                      a.value < b.value ? -1 : a.value > b.value ? 1 : 0
                    )
                );

                if (newValue.id !== "") {
                  let element = document.getElementById(
                    `errorM_${id}_locality`
                  );
                  if (element) {
                    element.style.display = "none";
                  }
                }
              }}
              disabled={
                isLoading || location.city === "" || localityList.length === 0
              }
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = localityList.find(
                  (option) => option?.value === inputValue
                );
                if (!matchedOption) {
                  setLocation({ ...location, locality: "" });
                  setLocalitySelected(null);
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  isError={isError[`errorM_${id}_locality`]}
                  isBlackTheme={false}
                  name={id}
                  disabled={isLoading}
                  placeholder={localityList.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!location.department, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
            <FormHelperText
              id={`errorM_${id}_locality`}
              ref={(el) => (errorRefs.current[`errorM_${id}_locality`] = el)}
              style={{
                color: colors.red,
                fontSize: 11,
                width: "100%",
                marginLeft: 0,
                marginRight: 0,
                lineHeight: 1.2,
                marginTop: 5,
                whiteSpace: "wrap",
                display: "none",
              }}
            />

            {/* Barrio Localidad*/}
            <Box mt={1}>
              <label
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  textWrap: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: isError[`errorM_${id}_neighborhood`]
                    ? colors.red
                    : location.locality === "" || nothing
                    ? colors.disabled
                    : "black",
                }}
                htmlFor={id}
              >
                {t(names[4])}
              </label>
            </Box>

            <Autocomplete
              options={neighList}
              getOptionLabel={(option) => option?.value}
              value={neighSelected}
              onChange={(event, newValue) => {
                setLocation({
                  ...location,
                  neighborhood: "",
                });
                setNeighSelected(null);
                setChanges((state: any) => ({
                  ...state,
                  [id]: {
                    ...state[id],
                    neighborhood: "",
                  },
                }));
                if (!newValue) return;

                handleChange({ neighborhood: newValue.id });

                setNeighSelected(newValue);
                if (newValue.id !== "") {
                  let element = document.getElementById(
                    `errorM_${id}_neighborhood`
                  );
                  if (element) {
                    element.style.display = "none";
                  }
                }
              }}
              disabled={isLoading || location.locality === ""}
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = neighList.find(
                  (option) => option?.value === inputValue
                );
                if (!matchedOption) {
                  setLocation({ ...location, neighborhood: "" });
                  setNeighSelected(null);
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  isError={isError[`errorM_${id}_neighborhood`]}
                  isBlackTheme={false}
                  name={id}
                  disabled={isLoading}
                  placeholder={neighList.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!location.neighborhood, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
            {nothing && (
              <FormHelperText
                style={{
                  color: colors.red,
                  fontSize: 11,
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  lineHeight: 1.2,
                  marginTop: 5,
                  whiteSpace: "wrap",
                }}
              >
                * No hay barrios para esta zona
              </FormHelperText>
            )}
            <FormHelperText
              id={`errorM_${id}_neighborhood`}
              ref={(el) =>
                (errorRefs.current[`errorM_${id}_neighborhood`] = el)
              }
              style={{
                color: colors.red,
                fontSize: 11,
                width: "100%",
                marginLeft: 0,
                marginRight: 0,
                lineHeight: 1.2,
                marginTop: 5,
                whiteSpace: "wrap",
                display: "none",
              }}
            />
          </>
        ) : (
          <>
            {/* Barrio */}
            <Box mt={1}>
              <label
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  textWrap: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: isError[`errorM_${id}_neighborhood`]
                    ? colors.red
                    : location.city === "" || nothing
                    ? colors.disabled
                    : "black",
                }}
                htmlFor={id}
              >
                {t(names[4])}
              </label>
            </Box>

            <Autocomplete
              options={neighList}
              getOptionLabel={(option) => option?.value}
              value={neighSelected}
              onChange={(event, newValue) => {
                setLocation({
                  ...location,
                  neighborhood: "",
                });
                setNeighSelected(null);
                setChanges((state: any) => ({
                  ...state,
                  [id]: {
                    ...state[id],
                    neighborhood: "",
                  },
                }));
                if (!newValue) return;

                handleChange({ neighborhood: newValue.id });

                // setValids((state: any) => ({
                //   ...state,
                //   neigh: newValue.id !== "",
                // }));

                setNeighSelected(newValue);
                if (newValue.id !== "") {
                  let element = document.getElementById(
                    `errorM_${id}_neighborhood`
                  );
                  if (element) {
                    element.style.display = "none";
                  }
                }
              }}
              disabled={
                isLoading || location.city === "" || neighList.length === 0
              }
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = neighList.find(
                  (option) => option?.value === inputValue
                );
                if (!matchedOption) {
                  setLocation({ ...location, neighborhood: "" });
                  setNeighSelected(null);
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  isError={isError[`errorM_${id}_neighborhood`]}
                  isBlackTheme={false}
                  name={id}
                  disabled={isLoading}
                  placeholder={neighList.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!location.neighborhood, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
            {nothing && (
              <FormHelperText
                style={{
                  color: colors.red,
                  fontSize: 11,
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  lineHeight: 1.2,
                  marginTop: 5,
                  whiteSpace: "wrap",
                }}
              >
                * No hay barrios para esta zona
              </FormHelperText>
            )}

            <FormHelperText
              id={`errorM_${id}_neighborhood`}
              ref={(el) =>
                (errorRefs.current[`errorM_${id}_neighborhood`] = el)
              }
              style={{
                color: colors.red,
                fontSize: 11,
                width: "100%",
                marginLeft: 0,
                marginRight: 0,
                lineHeight: 1.2,
                marginTop: 5,
                whiteSpace: "wrap",
                display: "none",
              }}
            />
          </>
        )
      ) : null}
    </Box>
  );
}
