import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { colors } from "../../../../styles/theme.tsx";
import { VisuallyHiddenInput } from "../IdUpload.tsx";
import { errorAlert } from "../../../../common/alerts.tsx";

export default function FormInputProfile({
  id,
  isLoading,
  setChanges,
  setValids,
}) {
  const { t } = useTranslation();
  const [tempPhoto, setTempPhoto] = React.useState<string>("");
  const [isError, setIsError] = React.useState(false);
  const errorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setValids((state: any) => ({ ...state, [id]: false }));
  }, []);

  // Escucha el cambio en el inpit
  React.useEffect(() => {
    if (!errorRef.current) return;

    // Cuando hay errores desde el padre - se muestra el error, y si se muestra el error - se cambia el color
    const observer = new MutationObserver(() => {
      if (errorRef.current) {
        setIsError(
          window.getComputedStyle(errorRef.current).display === "block"
        );
      }
    });

    observer.observe(errorRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let file = e.target.files[0];
      if (file) {
         // Validación de tipo
         const validTypes = ["image/png", "image/jpeg"];
         if (!validTypes.includes(file.type)) {
           errorAlert("Error", t("idUpload.onlyImg"), "");
           return;
         }
         // Validación de tamaño
         const maxSize = 1 * 1024 * 1024; // Tamaño máximo en bytes (1 MB)
         if (file.size > maxSize) {
           errorAlert("Error", t("idUpload.limit"), "");
           return;
         }
 
        setTempPhoto(URL.createObjectURL(file));
        const reader = new FileReader();
        reader.onloadend = () => {
          setChanges((state: any) => ({ ...state, [id]: reader.result }));
          setValids((state: any) => ({ ...state, [id]: true }));
          let element = document.getElementById(`errorM_${id}`);
          if (element) {
            element.style.display = "none";
          }
        };
        reader.readAsDataURL(file); // Lee el archivo como una URL de datos */
      }
    }
  };

  return (
    <Box>
      <Typography mt={1}>{t("validation.profilePhotoInfo")}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          mb: 4,
        }}
      >
        <img
          src={require("../../../../assets/image/Rostro.png")}
          width={200}
          height={200}
        />
      </Box>
      <Box>
        {/* BLOQUE PARA SUBIR LA FOTO Y PREVISUALIZARLA */}
        <label
          style={{
            fontSize: 13,
            fontWeight: 600,
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            color: isError ? colors.red : "",
          }}
          htmlFor={"cedulaF"}
        >
          {t("validation.profilePhoto")}
        </label>
        {tempPhoto ? (
          <Box sx={{ position: "relative", mt: 1 }}>
            <img
              src={tempPhoto}
              style={{ width: "100%", height: 150, objectFit: "contain" }}
            />

            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => {
                setTempPhoto("");
                setChanges((state: any) => ({ ...state, [id]: "" }));
                setValids((state: any) => ({ ...state, [id]: false }));
              }}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: colors.white,
                backgroundColor: colors.primary,
                borderRadius: 0,
                "&:hover": {
                  border: `1px solid ${colors.primary}`,
                  backgroundColor: colors.lightGray,
                  "& .MuiSvgIcon-root": {
                    color: colors.primary,
                  },
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <Button
            sx={{
              background: colors.primary,
              color: colors.white,
              fontSize: 16,
              mt: 1,
              lineHeight: 1.2,
              padding: 2,
              width: "100%",
            }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {t("validation.profilePhotoButton")}
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => handleChangeImage(e)}
              accept=".png, .jpg, .jpeg"
            />
          </Button>
        )}
      </Box>
      <FormHelperText
        id={`errorM_${id}`}
        ref={errorRef}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
    </Box>
  );
}
