/*
 * name validation
 * accepted: letters & spaces, minimum 3 chars, maximum 15 chars
 */
export const name: RegExp = /[a-zA-Z]{3,15}/;
export const minThreeCharacters: RegExp = /^.{3,}$/;
export const minSevenCharacters: RegExp = /^\d{7,}$/;


/* Solo se permiten letras y sin espacios al final*/
export const nameCharacters: RegExp = /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;

/*
 * email validation
 */
export const email: RegExp = /^[^\s@]+@[^\s@]+\.([^\s@]{2,})+$/;

/*
 * address validation
 */
export const calleRegex = /\b(ca|cll|caye|callej|call|c\.)\b/gi;
export const carreraRegex = /\b(crr|kra|cr|carera|crra|carrrea|k|cra\.)\b/gi;

export const location: RegExp =
  /^(?! )[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_.,#-]+(?: [a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_.,#-]+)*$/;


/* Permite caractes especiales tales como #, -, ., /, () */
export const companyAdress: RegExp = /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/;

/*
 * password validation, should contain:
 * (?=.*\d): at least one digit
 * (?=.*[a-z]): at least one lower case
 * (?=.*[A-Z]): at least one uppercase case
 * [0-9a-zA-Z]{6,}: at least 6 from the mentioned characters
 */
export const password: RegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/;

export const otp: RegExp = /\b\d{4}\b/;

export const phone: RegExp = /3[0-9]{9}/;

export const cedula: RegExp = /^((\d{8})|(\d{10})|(\d{11})|(\d{6}-\d{5}))?$/g;

export const direccion: RegExp =
  /^(Autopista|Avenida|Avenida Calle|Avenida Carrera|Avenida|Carrera|Calle|Carrera|Circunvalar|Diagonal|Kilometro|Transversal|AUTOP|AV|AC|AK|CL|KR|CCV|DG|KM|TV)(\s)?([a-zA-Z]{0,15}|[0-9]{1,3})(\s)?[a-zA-Z]?(\s)?(bis)?(\s)?(Este|Norte|Occidente|Oeste|Sur)?(\s)?(#(\s)?[0-9]{1,2}(\s)?[a-zA-Z]?(\s)?(bis)?(\s)?(Este|Norte|Occidente|Oeste|Sur)?(\s)?(-)?(\s)?[0-9]{1,3}(\s)?(Este|Norte|Occidente|Oeste|Sur)?)?((\s)?(Agrupación|Altillo|Apartamento|Apartamento Sótano|Barrio|Bloque|Bodega|Cabecera Municipal|Callejón|Camino|Carretera|Casa|Caserio|Célula|Centro|Centro Comercial|Centro Urbano|Circular|Condominio|Conjunto|Consultorio|Corregimiento|Deposito|Deposito |Sótano|Edificio|Entrada|Esquina|Etapa|Finca|Garaje|Garaje Sótano|Grada|Inferior|Inspección de Policia|Interior|Kilometro|Local|Local Mezzanine|Local Sótano|Lote|Manzana|Manzanita|Mejora|Mezzanine|Módulo|Municipio|Núcleo|Oficina|Oficina Sótano|Parcela|Parcelación|Pasaje|Penthouse|Piso|Porteria|Predio|Principal|Puente|Quebrada|Salon|Sector|Semisótano|Suite|Supermanzana|Terraza|Torre|Troncal|Unidad|Urbanización|Vereda|Via|Zona|AGN|AL|APTO|AS|BR|BL|BG|CM|CLJ|CN|CT|CA|CAS|CEL|CE|CECO|CEUR|CIR|CDM|CONJ|CS|CO|DP|DS|ED|EN|ESQ|ET|FCA|GJ|GS|GR|INF|IP|IN|KM|LC|LM|LS|LT|MZ|MZTA|MJ|MN|MD|MUN|NCO|OF|OS|PA|PCN|PSJ|PH|PI|PT|PD|PPAL|PN|QDA|SA|SEC|SS|SU|SMZ|TZ|TO|TRL|UN|URB|VDA|VIA|ZN)?(\s)?[1-9][0-9]{0,3})*$/;

export const phoneCode: RegExp = /^(\+?\d{1,3}|\d{1,4})$/;

export const stratum: RegExp = /\b^[1-6]\b/;

export const phoneHouse: RegExp = /[0-9]{7}/;

export const accountBank: RegExp = /^[0-9]{7,20}$/;

export const justNumbers: RegExp = /^[0-9]+$/;

export const justLetters: RegExp = /[a-zA-Z]/;

export const referrals: RegExp = /^LK-[0-9a-zA-Z]{6}$/;

export const url: RegExp =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
