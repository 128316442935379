import React from "react";
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../styles/theme.tsx";
import { CssTextField } from "../../../../components/InputField.tsx";

export default function FormsInputSelect({
  nameParam,
  setChanges,
  id,
  isLoading,
  internValue,
  keyValue,
  options,
  setValids,
}) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [valueSelected, setValueSelected] = React.useState<{
    id: number;
    value: string;
  } | null>(null);
  const [optionsMapeds, setOptionsMapeds] = React.useState<
    { id: number; value: string }[]
  >([]);
  const [isError, setIsError] = React.useState(false);
  const errorRef = React.useRef<HTMLDivElement | null>(null);

  // Escucha el cambio en el inpit
  React.useEffect(() => {
    if (!errorRef.current) return;

    // Cuando hay errores desde el padre - se muestra el error, y si se muestra el error - se cambia el color
    const observer = new MutationObserver(() => {
      if (errorRef.current) {
        setIsError(
          window.getComputedStyle(errorRef.current).display === "block"
        );
      }
    });

    observer.observe(errorRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  React.useEffect(() => {
    let flagValid: boolean | null = null;
    if (options.length > 10) {
      setOptionsMapeds(
        options.map((option: any) => ({
          id: option[keyValue],
          value: option[internValue],
        }))
      );
      flagValid = valueSelected && valueSelected?.value !== "";
    } else {
      flagValid = options[0][keyValue] + "" !== "";
    }

    setChanges((state: any) => ({
      ...state,
      [id]: options[0][keyValue],
    }));
    setValue(options[0][keyValue]);
    setValids((state: any) => ({ ...state, [id]: flagValid }));
    if (flagValid) {
      let element = document.getElementById(`errorM_${id}`);
      if (element) {
        element.style.display = "none";
      }
    }
  }, [valueSelected, setValueSelected]);

  return (
    <Box sx={{ marginTop: 1 }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: isError ? colors.red : "",
        }}
        htmlFor={id}
      >
        {t(nameParam)}
      </label>
      {options.length > 10 ? (
        <Autocomplete
          options={optionsMapeds}
          getOptionLabel={(option) => option.value}
          value={valueSelected}
          onChange={(event, newValue) => {
            if (!newValue) return;

            setChanges((state: any) => ({ ...state, [id]: newValue.id }));
            setValue(newValue.id + "");

            setValueSelected(newValue);
          }}
          disabled={isLoading || options.length === 0}
          onInputChange={(event, inputValue) => {
            // Si el valor ingresado no tiene una coincidencia, limpiar el campo
            const matchedOption = options.find(
              (option) => option?.value === inputValue
            );
            if (!matchedOption) {
              setValue("");
              setValueSelected(null);
            }
          }}
          noOptionsText={t("common.empty")}
          renderInput={(params) => (
            <CssTextField
              {...params}
              isError={isError}
              isBlackTheme={false}
              name={id}
              disabled={isLoading}
              placeholder={options.length === 0 ? "" : "Seleccione"}
              inputProps={{
                ...params.inputProps,
                readOnly: !!valueSelected, // Si hay una selección, el campo será de solo lectura
              }}
            />
          )}
        />
      ) : (

        <Select
          name={id}
          sx={{ width: "100%", mt:.8 }}
          value={value}
          onChange={({ target }) => {
            setChanges((state: any) => ({ ...state, [id]: target.value }));
            setValue(target.value);
          }}
        >
          {options.map((item) => (
            <MenuItem value={item[keyValue]}>{item[internValue]}</MenuItem>
          ))}
        </Select>
      )}
      <FormHelperText
        id={`errorM_${id}`}
        ref={errorRef}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
    </Box>
  );
}
