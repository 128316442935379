import { Box, InputAdornment, FormHelperText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../styles/theme.tsx";
import { CssTextField } from "../../../../components/InputField.tsx";

export default function FormsInputMoney({
  nameParam,
  setChanges,
  id,
  isLoading,
  setValids,
}) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>("");
  const [isError, setIsError] = React.useState(false);
  const errorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setValids((state: any) => ({ ...state, [id]: false }));
  }, []);

  // Escucha el cambio en el inpit
  React.useEffect(() => {
    if (!errorRef.current) return;

    // Cuando hay errores desde el padre - se muestra el error, y si se muestra el error - se cambia el color
    const observer = new MutationObserver(() => {
      if (errorRef.current) {
        setIsError(
          window.getComputedStyle(errorRef.current).display === "block"
        );
      }
    });

    observer.observe(errorRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  // Input para convertir a miles
  const handleChange = (elementValue: string) => {
    // Remover cualquier caracter que no sea un dígito
    const inputValue = parseInt(elementValue.replace(/\D/g, ""));
    const valueLocal = inputValue ?? 0;

    // Actualizar estados
    setChanges((state: any) => ({ ...state, [id]: valueLocal }));
    setValids((state: any) => ({ ...state, [id]: valueLocal >= 250000 }));

    // Formatear a pesos de miles
    const formattedValue = new Intl.NumberFormat("es-CO").format(inputValue);
    setValue(inputValue > 0 ? formattedValue : "0");
    const element = document.getElementById(`errorM_${id}`);

    if (element) {
      element.style.display = "none";
      setIsError(false);
    }
  };

  // Mostrar error al salir del foco
  const handleBlur = () => {
    // Verificar si el valor no cumple con la validación
    const valueLocal = parseInt(value.replace(/\D/g, "")) || 0;
    const element = document.getElementById(`errorM_${id}`);

    if (element) {
      if (valueLocal < 250000) {
        element.style.display = "block";
        element.innerHTML =
          "* El ingreso mensual debe ser mayor o igual a $250.000";
        setIsError(true);
      } else {
        element.style.display = "none";
        setIsError(false);
      }
    }
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: isError ? colors.red : "",
        }}
        htmlFor={id}
      >
        {t(nameParam)}
      </label>
      <CssTextField
        name={id}
        isError={isError}
        isBlackTheme={false}
        sx={{
          width: "100%",
        }}
        value={value}
        placeholder={`${t("common.changes")} ${t(nameParam)}`}
        onChange={({ target }) => handleChange(target.value)}
        onBlur={handleBlur}
        disabled={isLoading}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{ maxLength: 8 }}
      />
      <FormHelperText
        id={`errorM_${id}`}
        ref={errorRef}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
    </Box>
  );
}
