import {
  Box,
  Button,
  FormHelperText,
  IconButton,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { colors } from "../../../../styles/theme.tsx";
import { VisuallyHiddenInput } from "../IdUpload.tsx";
import { errorAlert } from "../../../../common/alerts.tsx";

export default function FormsInputIds({
  id,
  setChanges,
  setValids,
}) {
  const { t } = useTranslation();
  const [simpleInformation, setSimpleInformation] = React.useState({
    photoIdFront: "",
    photoIdBack: "",
  });
  const [isError, setIsError] = React.useState(false);
  const errorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setValids((state: any) => ({
      ...state,
      [id]:
        simpleInformation.photoIdFront !== "" &&
        simpleInformation.photoIdBack !== "",
    }));
    if (
      simpleInformation.photoIdFront !== "" &&
      simpleInformation.photoIdBack !== ""
    ) {
      let element = document.getElementById(`errorM_${id}`);
      if (element) {
        element.style.display = "none";
      }
    }
  }, [simpleInformation, setSimpleInformation]);

  // Escucha el cambio en el inpit
  React.useEffect(() => {
    if (!errorRef.current) return;

    // Cuando hay errores desde el padre - se muestra el error, y si se muestra el error - se cambia el color
    const observer = new MutationObserver(() => {
      if (errorRef.current) {
        setIsError(
          window.getComputedStyle(errorRef.current).display === "block"
        );
      }
    });

    observer.observe(errorRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idPhoto: string
  ) => {
    if (e.target.files) {
      let file = e.target.files[0];
      if (file) {
        // Validación de tipo
        const validTypes = ["image/png", "image/jpeg"];
        if (!validTypes.includes(file.type)) {
          errorAlert("Error", t("idUpload.onlyImg"), "");
          return;
        }
        // Validación de tamaño
        const maxSize = 1 * 1024 * 1024; // Tamaño máximo en bytes (1 MB)
        if (file.size > maxSize) {
          errorAlert("Error", t("idUpload.limit"), "");
          return;
        }

        if (idPhoto === "f") {
          const reader = new FileReader();
          reader.onloadend = () => {
            setChanges((state: any) => ({
              ...state,
              [id]: { ...state[id], photoIdFront: reader.result },
            }));
          };
          reader.readAsDataURL(file);
          setSimpleInformation({
            ...simpleInformation,
            photoIdFront: URL.createObjectURL(file),
          });
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            setChanges((state: any) => ({
              ...state,
              [id]: { ...state[id], photoIdBack: reader.result },
            }));
          };
          reader.readAsDataURL(file);
          setSimpleInformation({
            ...simpleInformation,
            photoIdBack: URL.createObjectURL(file),
          });
        }
      }
    }
  };

  const handleResetPhoto = (idPhoto: string) => {
    setValids((state: any) => ({ ...state, [id]: false }));
    if (idPhoto === "f") {
      setChanges((state: any) => ({
        ...state,
        [id]: { ...state[id], photoIdFront: "" },
      }));
      setSimpleInformation({ ...simpleInformation, photoIdFront: "" });
    } else {
      setChanges((state: any) => ({
        ...state,
        [id]: { ...state[id], photoIdBack: "" },
      }));
      setSimpleInformation({ ...simpleInformation, photoIdBack: "" });
    }
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      {/* FOTO FRONTAL */}
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: isError && !simpleInformation.photoIdFront ? colors.red : "",
        }}
        htmlFor={"cedulaF"}
      >
        {t("validation.frontPart")}
      </label>
      {simpleInformation.photoIdFront ? (
        <Box sx={{ position: "relative", mt: 1 }}>
          <img
            src={simpleInformation.photoIdFront}
            style={{ width: "100%", height: 150, objectFit: "contain" }}
          />
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => handleResetPhoto("f")}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: colors.white,
              backgroundColor: colors.primary,
              borderRadius: 0,
              "&:hover": {
                border: `1px solid ${colors.primary}`,
                backgroundColor: colors.lightGray,
                "& .MuiSvgIcon-root": {
                  color: colors.primary,
                },
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : (
        <Button
          sx={{
            background: colors.primary,
            color: colors.white,
            fontSize: 16,
            mt: 1,
            lineHeight: 1.2,
            padding: 2,
            width: "100%",
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {t("validation.frontPartButton")}
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => handleChange(e, "f")}
            accept=".png, .jpg, .jpeg"
          />
        </Button>
      )}

      {/* FOTO ATRAS */}
      <Box mt={1}>
        <label
          style={{
            fontSize: 13,
            fontWeight: 600,
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            color: isError && !simpleInformation.photoIdBack ? colors.red : "",
          }}
          htmlFor={"cedulaF"}
        >
          {t("validation.backPart")}
        </label>
      </Box>

      {simpleInformation.photoIdBack ? (
        <Box sx={{ position: "relative", mt: 1 }}>
          <img
            src={simpleInformation.photoIdBack}
            style={{ width: "100%", height: 150, objectFit: "contain" }}
          />
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => handleResetPhoto("b")}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: colors.white,
              backgroundColor: colors.primary,
              borderRadius: 0,
              "&:hover": {
                border: `1px solid ${colors.primary}`,
                backgroundColor: colors.lightGray,
                "& .MuiSvgIcon-root": {
                  color: colors.primary,
                },
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : (
        <Button
          sx={{
            background: colors.primary,
            color: colors.white,
            fontSize: 16,
            mt: 1,
            lineHeight: 1.2,
            padding: 2,
            width: "100%",
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {t("validation.backPartButton")}
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => handleChange(e, "b")}
            accept=".png, .jpg, .jpeg"
          />
        </Button>
      )}
      <FormHelperText
        id={`errorM_${id}`}
        ref={errorRef}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
    </Box>
  );
}
