import React from "react";
import { KeyboardBackspaceIcon } from "../../assets/iconsMui.tsx";
import { useTranslation } from "react-i18next";
import { IRegisterStep } from "../../../User/RegisterComponent.tsx";
import {
  getValueStorage,
  saveValueStorage,
} from "../../../../common/storage.ts";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import {
  confirmationAlert,
  Modal,
  ModalDashboard,
  MySwal,
} from "../../../../common/alerts.tsx";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import { ColorButton } from "../../../../components/Button.tsx";
import { ModalVideo } from "../../../../components/Modals.tsx";
import FadeAnim from "../../../../components/animations/FadeAnim.tsx";
import { VideoScreen } from "../../../../components/VideoScreen.tsx";
import {
  blobToFile,
  detectDevice,
  JSONToForm,
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../../../../common/CommonFunctions.tsx";
import {
  IParseRenovation,
  IUserAuth,
  navigationModals,
} from "../../../../constants/types.tsx";
import renovationStep from "../../services/RenovationStep.tsx";

interface IRenovationHousePanning {
  onClose?: any;
  setIsLoading: (isLoading: boolean) => void;
  parseRenovacion?: () => any;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  parseRenovacionCustom?: IParseRenovation;
  videoUrlParam?: string;
}

export default function RenovationHousePanning({
  setRegisterStep,
  videoUrlParam,
  setIsLoading,
}: IRenovationHousePanning) {
  const { t } = useTranslation();
  const [recordVideo, setRecortdVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(videoUrlParam ?? "");
  const { getUser } = React.useContext(AuthContext);
  const { parseRenovacion, updatePasoRenovacion } = useUpdatePasoRenovacion();

  // Lanzar advertencia para repetir el video
  const restoreVideo = async (videoUrl: string) => {
    const res = await confirmationAlert(
      t("workVideo.alertTitle"),
      t("workVideo.alertMessage"),
      t("workVideo.alertRepeat")
    );
    if (res) {
      // Repetir
      if (setRegisterStep) {
        setVideoUrl("");
        setRecortdVideo(true);
      } else {
        ModalDashboard({
          element: (
            <RenovationHousePanning
              setRegisterStep={setRegisterStep}
              setIsLoading={setIsLoading}
              videoUrlParam=""
            />
          ),
        });
      }
    } else {
      // Cancelar
      if (!setRegisterStep) {
        ModalDashboard({
          element: (
            <RenovationHousePanning
              setRegisterStep={setRegisterStep}
              setIsLoading={setIsLoading}
              videoUrlParam={videoUrl}
            />
          ),
        });
      }
    }
  };

  const handleSeeGuideVideo = () => {
    MySwal.close();
    Modal(
      <ModalVideo
        url={"https://www.youtube.com/embed/7qmNIp_XCrQ"}
        onClose={() => MySwal.close()}
      />,
      {
        title: "",
        showCloseButton: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 800,
        didClose: () => {
          ModalDashboard({
            element: (
              <RenovationHousePanning
                setRegisterStep={setRegisterStep}
                setIsLoading={setIsLoading}
                videoUrlParam={videoUrl}
              />
            ),
          });
        },
      }
    );
  };

  //Enviar el video de la casa
  const handleSendHouseVideo = async () => {
    setIsLoading(true);
    const authSession: IUserAuth = getValueStorage("authSession");

    let idVideoCall = "";
    let idLoan =
      authSession.inProcessRenovation?.inProcessRenovation || authSession.isOld
        ? authSession.loanRequest!.c200_rowid
          ? authSession.loanRequest!.c200_rowid
          : ""
        : authSession.hasVideoCall.c155_rowid_prestamo;

    if (authSession?.hasVideoCallThird?.c155_rowid) {
      idVideoCall = authSession.hasVideoCallThird.c155_rowid;
    } else if (authSession?.hasVideoCall?.c155_rowid) {
      idVideoCall = authSession.hasVideoCall.c155_rowid;
    }

    const file = await blobToFile(videoUrl, `${idLoan}_casa`);

    const objToSend = {
      c125_url_video_casa: file ?? "",
      c125_lat_casa: authSession.getLocation?.latitude!.toString() ?? "",
      c125_lon_casa: authSession.getLocation?.longitude!.toString() ?? "",
      c200_rowid: idLoan ?? "",
      c155_rowid: idVideoCall ?? "",
      c110_rowid: authSession.revalidateHouseVideo.c110_rowid ?? "",
    };

    const formData = JSONToForm(objToSend);

    const res = await requestSquematic(
      "POST",
      "/api/app/contact_details/save_house_recorded",
      formData,
      authSession.token
    );

    if (res && res.results) {
      if (parseRenovacion().tipo_videollamada === "2") {
        // Si es independiente
        await updatePasoRenovacion("8", setIsLoading, "2");
        if (setRegisterStep) {
          let obj = {
            prevStep: 32,
            nextStep: 28,
            desc: "Videocasa a WorkVideo",
          };
          saveValueStorage("registerStep", obj);
          setRegisterStep(obj);
        } else {
          let dinamicElement = renovationStep({
            setIsLoading: setIsLoading,
            parseRenovacion: parseRenovacion,
          });
          if (dinamicElement) {
            ModalDashboard({
              element: dinamicElement,
            });
          }
        }
      } else {
        await updatePasoRenovacion("0", setIsLoading);
        if (setRegisterStep) {
          let obj = {
            prevStep: 32,
            nextStep: 24,
            desc: "desp videocasa a Waiting",
          };
          saveValueStorage("registerStep", obj);
          setRegisterStep(obj);
        } else {
          let dinamicElement = renovationStep({
            setIsLoading: setIsLoading,
            parseRenovacion: parseRenovacion,
          });
          if (dinamicElement) {
            ModalDashboard({
              element: dinamicElement,
            });
          }
        }
      }
    }

    setIsLoading(false);
  };

  //Volver atras
  const handleBack = () => {
    if (setRegisterStep) {
      const registerStep: { nextStep: number; prevStep: number; desc: string } =
        getValueStorage("registerStep");
      let objectStep: IRegisterStep = {
        prevStep: 31,
        nextStep: registerStep.prevStep,
        desc: "pasa a ver los referidos",
      };
      saveValueStorage("registerStep", objectStep);
      setRegisterStep(objectStep);
    } else {
      //aqui va el modal jordan
    }
  };
  
  // Al recargar la página, se lanza la advertencia para no perder el video
  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Mostrar mensaje de advertencia
      event.preventDefault();
      event.returnValue = ""; // Esto asegura que el mensaje de confirmación del navegador se muestre.
    };


    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [videoUrl]);

  return (
    <>
      {recordVideo ? (
        <VideoScreen
          setIsLoading={setIsLoading}
          setVideoUrl={setVideoUrl}
          onClose={setRecortdVideo}
          setRegisterStep={setRegisterStep}
        />
      ) : (
        <>
          {setRegisterStep && (
            <div style={{ marginBottom: 30 }} className="relative">
              <button
                className="absolute flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary top-0 left-[-10px]"
                onClick={handleBack}
              >
                <KeyboardBackspaceIcon />
                <p className="text-sm ">{t("common.back")}</p>
              </button>
            </div>
          )}
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box sx={{ width: 200, mt: 5, marginBottom: 2 }}>
              <img
                src={require("../../assets/images/ValidationHouse.webp")}
                alt={""}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ mx: setRegisterStep ? 0 : 1 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.primary,
                textTransform: "uppercase",
                mt: 4,
                lineHeight: 1,
                textAlign: "center",
              }}
            >
              {t("renovationHousePanning.title")}
            </Typography>
            <Typography
              className="dark:text-white"
              sx={{
                fontWeight: "bold",
                fontSize: 18,
                color: colors.primary,
                textTransform: "uppercase",
                marginBottom: 2,
                lineHeight: 1,
                textAlign: "center",
              }}
            >
              {t("renovationHousePanning.place")}
            </Typography>
            <Typography
              className="dark:text-white"
              sx={{ fontSize: 16, marginBottom: "5px", lineHeight: 1.1 }}
            >
              {t("renovationHousePanning.desc1").split("-")[0]}
              <strong>{t("renovationHousePanning.desc1").split("-")[1]}</strong>
              {t("renovationHousePanning.desc2").split("-")[0]}
              <strong>{t("renovationHousePanning.desc2").split("-")[1]}</strong>
              {t("renovationHousePanning.desc3")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 4,
            }}
          >
            {detectDevice() === "Desktop" && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"left"}
                marginTop={2}
                mx={setRegisterStep ? 0 : 2}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: colors.red,
                    textTransform: "uppercase",
                    marginBottom: "20px",
                  }}
                  lineHeight={1}
                >
                  ¡{t("videoCall.attention")}!
                </Typography>
                <Typography
                  className="dark:text-white"
                  sx={{
                    fontSize: 16,
                    color: colors.black,
                    marginBottom: "20px",
                    lineHeight: 1.2,
                  }}
                >
                  {t("workVideo.attention")}
                  <strong> {t("videoCall.attentionPointP2")}</strong>
                  {t("workVideo.attentionPointP3")}
                </Typography>
              </Box>
            )}
            {detectDevice() !== "Desktop" && (
              <>
                <ColorButton onClick={handleSeeGuideVideo}>
                  {t("workVideo.textGuide")}
                </ColorButton>
                {videoUrl ? (
                  <Box my={6}>
                    <video controls src={videoUrl} disablePictureInPicture />
                    <FadeAnim>
                      <button
                        disabled={getUser.isLoading}
                        style={{ width: "100%" }}
                        onClick={() => restoreVideo(videoUrl)}
                      >
                        <Typography
                          className="dark:text-white"
                          sx={{
                            color: colors.slateGray,
                            textAlign: "center",
                          }}
                          lineHeight={1.2}
                          mt={2}
                          mx={{ xs: "20%", sm: 12, xl: 15 }}
                        >
                          {t("uploadFile.pressHere")}
                          <strong>{t("uploadFile.subPressHere")}</strong>
                          {t("workVideo.retake")}
                        </Typography>
                      </button>
                    </FadeAnim>
                  </Box>
                ) : (
                  <Box my={8}>
                    <button
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => setRecortdVideo(true)}
                      disabled={getUser.isLoading}
                    >
                      <img
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                        }}
                        src={require("../../assets/images/Video.webp")}
                        alt=""
                      />
                      <Typography
                        className="dark:text-white"
                        sx={{
                          color: colors.slateGray,
                          mt: -1,
                          textAlign: "center",
                          fontSize: 12,
                        }}
                        lineHeight={1.2}
                      >
                        {t("workVideo.maxVideo")}
                      </Typography>

                      <Typography
                        className="dark:text-white"
                        sx={{ color: colors.slateGray, textAlign: "center" }}
                        marginTop={1}
                        lineHeight={1.2}
                        mx={{ xs: "20%", sm: 12, xl: 13 }}
                      >
                        {t("uploadFile.pressHere")}
                        <strong>{t("uploadFile.subPressHere")}</strong>
                        {t("workVideo.record")}
                      </Typography>
                    </button>
                  </Box>
                )}
                <ColorButton
                  onClick={handleSendHouseVideo}
                  sx={{ paddingY: 1, fontSize: 18 }}
                  disabled={getUser.isLoading || !videoUrl}
                >
                  {t("common.continue")}
                </ColorButton>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
}
