import React from "react";
import { useTranslation } from "react-i18next";
import { Box, FormHelperText} from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import { CssTextField } from "../../../../components/InputField.tsx";

export default function FormsInputDate({
  nameParam,
  setChanges,
  id,
  isLoading,
  setValids,
  condition,
}) {
  const { t } = useTranslation();
  const [date, setDate] = React.useState<any>("");
  const [isError, setIsError] = React.useState(false);
  const errorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const resCondition = condition(date);
    setChanges((state: any) => ({
      ...state,
      [id]: date,
    }));
    setValids((state: any) => ({ ...state, [id]: resCondition }));
    if (date !== "") {
      let element = document.getElementById(`errorM_${id}_date`);
      if (element) {
        element.style.display = "none";
      }
    }
  }, [setDate, date]);

  // Escucha el cambio en el inpit
  React.useEffect(() => {
    if (!errorRef.current) return;

    // Cuando hay errores desde el padre - se muestra el error, y si se muestra el error - se cambia el color
    const observer = new MutationObserver(() => {
      if (errorRef.current) {
        setIsError(
          window.getComputedStyle(errorRef.current).display === "block"
        );
      }
    });

    observer.observe(errorRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Box sx={{ marginTop: 1 }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: isError ? colors.red : "",
        }}
        htmlFor={id}
      >
        {t(nameParam)}
      </label>
      <CssTextField
        isBlackTheme={false}
        isError={isError}
        type="date"
        sx={{
          width: "100%",
        }}
        id={id}
        name={id}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          max: new Date().toISOString().split("T")[0],
        }}
        onChange={({ target }) => setDate(target.value)}
      />

      <FormHelperText
        id={`errorM_${id}_date`}
        ref={errorRef}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
    </Box>
  );
}
