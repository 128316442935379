import React from "react";
import { Box, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CssTextField } from "../../../../components/InputField.tsx";
import { colors } from "../../../../styles/theme.tsx";

export function FormsInputName({
  nameParam,
  setChanges,
  id,
  setValids,
  isLoading,
  condition,
  maxLength,
}: {
  nameParam: string;
  setChanges: any;
  id: string;
  setValids: any;
  isLoading: boolean;
  condition?: any;
  maxLength?: number;
}) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const errorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setValids((state: any) => ({ ...state, [id]: value !== "" }));
  }, []);

  // Escucha el cambio en el inpit
  React.useEffect(() => {
    if (!errorRef.current) return;

    // Cuando hay errores desde el padre - se muestra el error, y si se muestra el error - se cambia el color
    const observer = new MutationObserver(() => {
      if (errorRef.current) {
        setIsError(
          window.getComputedStyle(errorRef.current).display === "block"
        );
      }
    });

    observer.observe(errorRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  const handleChange = (elementValue: string) => {
    if (elementValue !== "") {
      let element = document.getElementById(`errorM_${id}_name`);
      if (element) {
        element.style.display = "none";
      }
    }

    const resCondition = condition(elementValue);
    setChanges((state: any) => ({ ...state, [id]: elementValue }));
    setValue(elementValue);
    setValids((state: any) => ({ ...state, [id]: resCondition }));
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: isError ? colors.red : "",
        }}
        htmlFor={id}
      >
        {t(nameParam) ?? ""}
      </label>
      <CssTextField
        isBlackTheme={false}
        isError={isError}
        name={id}
        sx={{
          width: "100%",
        }}
        type="text"
        value={value}
        placeholder={`${
          id === "1" ? t("common.changes") : t("common.singleChange")
        }${t(nameParam) ?? ""}`}
        onChange={({ target }) => handleChange(target.value)}
        disabled={isLoading}
        inputProps={{
          maxLength: maxLength, // Máximo de caracteres
          inputMode: "text",
        }}
      />

      <FormHelperText
        id={`errorM_${id}_name`}
        ref={errorRef}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
    </Box>
  );
}

export function FormsInputNumber({
  nameParam,
  setChanges,
  id,
  setValids,
  isLoading,
  condition,
  maxLength,
}: {
  nameParam: string;
  setChanges: any;
  id: string;
  setValids: any;
  isLoading: boolean;
  condition?: any;
  maxLength?: number;
}) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const errorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setValids((state: any) => ({ ...state, [id]: value !== "" }));
  }, []);

  // Escucha el cambio en el inpit
  React.useEffect(() => {
    if (!errorRef.current) return;

    // Cuando hay errores desde el padre - se muestra el error, y si se muestra el error - se cambia el color
    const observer = new MutationObserver(() => {
      if (errorRef.current) {
        setIsError(
          window.getComputedStyle(errorRef.current).display === "block"
        );
      }
    });

    observer.observe(errorRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  const handleChange = (elementValue: string) => {
    if (elementValue !== "") {
      let element = document.getElementById(`errorM_${id}_number`);
      if (element) {
        element.style.display = "none";
      }
    }

    elementValue = elementValue.replace(/[^0-9]/g, "");

    const resCondition = condition(elementValue);
    setChanges((state: any) => ({ ...state, [id]: elementValue }));
    setValue(elementValue);
    setValids((state: any) => ({ ...state, [id]: resCondition }));
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: isError ? colors.red : "",
        }}
        htmlFor={id}
      >
        {t(nameParam) ?? ""}
      </label>
      <CssTextField
        isBlackTheme={false}
        isError={isError}
        name={id}
        sx={{
          width: "100%",
        }}
        type="text"
        value={value}
        placeholder={`${
          id === "1" ? t("common.changes") : t("common.singleChange")
        }${t(nameParam) ?? ""}`}
        onChange={({ target }) => handleChange(target.value)}
        disabled={isLoading}
        inputProps={{
          maxLength: maxLength, // Máximo de caracteres
          inputMode: "numeric", // Ayuda en dispositivos móviles
        }}
        onInput={(e: React.FormEvent<HTMLInputElement>) => {
          // Remueve cualquier carácter que no sea un numero
          const target = e.target as HTMLInputElement;
          target.value = target.value.replace(/[^0-9]/g, "");
        }}
      />

      <FormHelperText
        id={`errorM_${id}_number`}
        ref={errorRef}
        style={{
          color: colors.red,
          fontSize: 11,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.2,
          marginTop: 5,
          whiteSpace: "wrap",
          display: "none",
        }}
      />
    </Box>
  );
}
